/* A custom stylesheet for Video JS themed for RHB Connect */

.vjs-rhb .vjs-control-bar {
  background-color: #2e3039;
}

.vjs-rhb .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
