html,
body,
.App,
#root {
  height: 100%;
}

*:focus {
  outline: none;
}
